import React from "react";
import CategoryTeam from "../CategoryTeam";
import { AdminFinanceProfile } from "./AdminFinance";

function AdminFinanceTeam() {
  return (
    <div className="">
      {AdminFinanceProfile.map((category) => (
        <CategoryTeam key={category.id} ourTeam={category} />
      ))}
    </div>
  );
}

export default AdminFinanceTeam;
