import React from "react";

function JoinTeam() {
  return (
    <div className="pt-4 flex flex-col justify-center items-center ">
      <h1 className="font-roboto   text-2xl md:text-3xl font-semibold md:pt-5">
        {" "}
        Join Our team
      </h1>
      <div className=" border-t-4 border-pink-600 w-1/2 md:w-1/3 lg:w-1/6 "></div>
      <p
        className="font-roboto text-justify text-sm md:text-lg max-w-5xl p-6 font-base "
        style={{ color: "var(--Black-2, #4F4F4F)" }}
      >
        Join our dynamic team to shape the future of XBI4. Your talents and
        ideas are cherished in our supportive environment. Be part of a
        community committed to positive change, with inclusivity, growth, and
        innovation as our foundation. Join us today to leave a lasting mark on
        your career and the world.
      </p>
    </div>
  );
}

export default JoinTeam;
