import React, { useEffect, useState } from "react";
import Reach from "./components/Reach";
import Company from "./components/Company";
import Legal from "./components/Legal";
import Links from "./components/Links";
import Social from "./components/social";
import GetInTouch from "./components/GetInTouch";

const Footer1 = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update windowWidth when the window is resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add an event listener to the window resize event
    window.addEventListener("resize", handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ backgroundColor: "#222222" }}>
      <div className=" grid row-span-1 grid-flow-row-dense gap-5 sm:col-span-1 sm:grid-flow-col-dense  p-5 mx-10 border-t-2 border-t-white text-white">
        <Reach />
        <div
          className={`${
            windowWidth > 728 ? "grid grid-flow-col row-span-1" : "hidden"
          } gap-3 `}
        >
          <Company />
          {/* <Legal /> */}
          {/*  <Links /> */}
        </div>
        <div
          className={`${
            windowWidth > 728 ? "grid grid-flow-col row-span-1" : "hidden"
          } gap-3 `}
        >
          <GetInTouch />
          {/* <Legal /> */}
          {/*  <Links /> */}
        </div>
        <div className="col-span-2">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.516325438933!2d77.24869187458525!3d9.016573889184237!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b0681b309f52987%3A0xc2602bf3413d837a!2sXBI4!5e0!3m2!1sen!2sin!4v1695626367422!5m2!1sen!2sin"
            width="100%"
            height="300"
            style={{ border: "2px solid white" }}
            allowFullScreen={true}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <div>
        <Social />
        <p className="text-white flex justify-center">© 2023 Copyright: XBI4</p>
      </div>
    </div>
  );
};

export default Footer1;
