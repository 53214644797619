import React from "react";
import CategoryTeam from "../CategoryTeam";
import { CreativesTeamProfile } from "./CreativesTeamImg";

const CreativesTeam = () => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-4 text-center gap-10 ">
      {CreativesTeamProfile.map((category: any) => (
        <CategoryTeam key={category.id} ourTeam={category} />
      ))}
    </div>
  );
};

export default CreativesTeam;
