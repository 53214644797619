import { GET } from "../../helper/restFunctions";

const getJobTitle = async () => {
  const path = `/looking_for_job`;
  return await GET(path)
    .then((result) => {
      console.log("GetjobTitle",result);
      return result;
    })
    .catch((err) => {
      return err;
    });
};

export default getJobTitle;
