import React, { useState } from "react";
import Button from "../../components/UI/Button";
import { BsPlusLg, BsArrowUpRightCircle } from "react-icons/bs";
import { BiMinus } from "react-icons/bi";
import { AccordianWomen, Accordian1 } from "../../assets/index";
import { whyDetails, Details } from "../../Data/WhyUs";
import ProjectImageCard from "../../components/ProjectImageCard";
import ProjectCard from "../../components/ProjectCard";
import { Link } from "react-router-dom";

const WhyUs = () => {
  const [isVisible, setIsVisible] = useState(-1);
  const handleClick = (index: number) => {
    if (isVisible === index) {
      // If the clicked accordion is already open, close it
      setIsVisible(-1);
    } else {
      // Open the clicked accordion
      setIsVisible(index);
    }
  };

  return (
    <div className="max-w-screen-xl mx-auto p-10">
      <div>
        <div className="flex flex-wrap items-center gap-10 justify-around">
          <div>
            <img src={AccordianWomen} alt="" />
          </div>
          <div className="flex flex-col gap-4 w-[450px]">
            <h2 className="text-2xl font-bold">Why us ???</h2>
            {whyDetails.map(({ heading, description }, index) => (
              <div key={index} className="m-3 pb-5 border-b-2 border-black">
                <div className="flex justify-between">
                  <p className="text-xl">{heading}</p>
                  <Button onClick={() => handleClick(index)}>
                    {isVisible === index ? <BiMinus /> : <BsPlusLg />}
                  </Button>
                </div>
                {isVisible === index && (
                  <p
                    className={`text-gray-700  bg-white shadow-xl p-3 rounded-lg   animate-transitionSlow ${
                      isVisible != -1 ? "opacity-100" : "opacity-0"
                    }`}
                  >
                    {description}
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="pt-10">
        <div className="pb-5">
          <h1 className="text-2xl font-bold ml-4 py-5">
            Jump into the <br /> Consulting tools
          </h1>
          <div className="grid grid-flow-col-dense overflow-x-scroll gap-14 p-3 [&::-webkit-scrollbar]:hidden [-ms-overflow-style:'none'] [scrollbar-width:'none']">
            {Details.map(({ title, description }, index) => (
              <div
                key={index}
                className="flex flex-col items-center justify-between gap-5 min-w-[250px] max-w-[350px]"
              >
                <ProjectImageCard
                  conStyle="w-[100%]"
                  imgPath={Accordian1}
                  showBackground={false}
                />
                <ProjectCard
                  title={title}
                  titleStyle="bg-[#F8FFCE] max-w-fit px-2 rounded-md"
                  description={description}
                  descriptionStyle="text-gray-600 line-clamp-4"
                  showButton={false}
                />
                <Link
                  to={
                    {
                      pathname: `blog/${index + 1}`,
                      state: { scrollPosition: window.scrollY },
                    } as any
                  }
                >
                  <BsArrowUpRightCircle size={25} className="cursor-pointer" />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
