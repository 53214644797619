import React from "react";
import Card from "../../components/Card";
import OurValues from "../../Data/OurValues";
interface Post {
  id: number;
  title: string;
  content: string;
  imageUrl: string;
}

export default function CardContent() {
  const titleStyle =
    "font-roboto text-center font-semibold text-base sm:text-xl pb-1";
  const bodyStyle =
    "bg-gray-100 border border-gray-300 rounded-3xl shadow-lg  flex flex-col items-center   px-1 sm:p-1 sm:p-4  md:mb-3";
  const descriptionStyle =
    "text-gray-600 text-center font-roboto text-xs md:text-base lg:text-sm font-normal ";
  return (
    <div className="">
      <div className="w-full  px-4 py-6">
        <div className="font-roboto font-semibold text-2xl md:text-3xl lg:text-2xl ml-5   ">
          Our Values
        </div>
        <div className="border-b border-gray-300 my-4"></div>
      </div>
      <div className="flex justify-center items-center ">
        <div className="  grid grid-cols-2  md:grid-cols-3 md:p-5  gap-4 px-2  lg:gap-x-10 lg:gap-y-8  lg:w-8/12  ">
          {OurValues.map((value, index) => (
            <Card
              key={index}
              bodyStyle={bodyStyle}
              img={value.imageUrl}
              alt={""}
              titleStyle={titleStyle}
              title={value.title}
              descriptionStyle={descriptionStyle}
              description={value.content}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

{
}
{
}
