import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar";

const layout = () => {
  return (
    <>
      <div className=" fixed w-full z-50 sm:top-0 sm:z-30 sm:w-100vw sm:bg-white">
        <Navbar />
      </div>
      <div className="pt-20 -z-50 w-90vw ">
        <Outlet />
      </div>
    </>
  );
};

export default layout;
