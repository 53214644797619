import React, { useEffect } from "react";
import ProjectCard from "../components/ProjectCard";
import { useSearchParams } from "react-router-dom";
import { ProductsData } from "../Data/OurProductsData";

import { any } from "zod";

const OurProducts = () => {
  const titleStyle = "text-2xl  font-semibold ";
  const descriptionStyle = "text-lg m-3 text-justify";

  //   const imageClass = id % 2 === 1 ? "flex-row" : "flex-row-reverse";
  const [searchParams] = useSearchParams();
  const value: any = searchParams.get("item");
  console.log(value);
  useEffect(() => {
    // Ensure there is an item in the URL parameters and the component is mounted
    if (value) {
      // Attempt to find the element with the given ID
      const element: any = document.getElementById(value);

      // If the element exists, scroll to it
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        // Additional scroll to adjust the position with an offset
        const elementRect = element.getBoundingClientRect();
        const absoluteElementTop = elementRect.top + window.pageYOffset;
        const middle = absoluteElementTop - 100; // Adjust the offset here
        window.scrollTo({ top: middle, behavior: "smooth" });
      }
    }
  }, [value]);

  return (
    <div className="flex flex-col">
      <div className="m-5 flex  flex-col gap-5">
        <h1 className="text-2D2D2D text-4xl  font-semibold ">
          Our Best Refined
        </h1>
        <h1 className=" bg-gradient-to-r from-purple-800 to-indigo-800 bg-clip-text text-transparent text-5xl  font-semibold ">
          Consulting Management Tools
        </h1>
      </div>
      {ProductsData.map((value, index) => (
        <div
          key={value.id}
          className={`flex flex-col justify-center items-center md:px-20 ${
            value.id % 2 === 1
              ? "md:flex-row-reverse bg-white text-black"
              : "md:flex-row bg-black text-white"
          }`}
        >
          <div
            className="flex flex-col w-full md:w-1/2 p-4 m-9 "
            id={value.productId}
          >
            <ProjectCard
              title={value.title}
              titleStyle={titleStyle}
              description={value.titleDesc}
              descriptionStyle={descriptionStyle}
              showButton={false}
            />

            <ProjectCard
              title={value.feature}
              titleStyle="text-xl"
              description={
                <ul className="list-disc pl-6">
                  {value.featureDesc.map((item, index) => (
                    <li key={index} className="">
                      {item}
                    </li>
                  ))}
                </ul>
              }
              descriptionStyle={descriptionStyle}
              showButton={false}
            />
          </div>
          <div className="md:w-1/2 w-full pb-4">
            <img src={value.imgUrl} alt="" className="mx-auto " />
          </div>
        </div>
      ))}
    </div>
  );
};

export default OurProducts;
