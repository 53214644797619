import React, { useState } from "react";
import xbi4_logo from "../assets/xbi4-Logo.png";
import { FaBars, FaTimes } from "react-icons/fa";
import { FaHome } from "react-icons/fa";
import Button from "./UI/Button";
import Contact from "../pages/contact/Contact";
import Project from "../pages/product/Project";
import { Link } from "react-router-dom";

const Navbar: React.FC = () => {
  const [nav, setNav] = useState(false);
  const items = [
    {
      id: 1,
      item: "Home",
      path: "",
    },
    {
      id: 2,
      item: "Services",
      path: "/service",
    },
    {
      id: 3,
      item: "Products",
      path: "/ourProducts",
    },
    {
      id: 4,
      item: "About us",
      path: "/aboutUs",
    },
    {
      id: 5,
      item: "Our team",
      path: "/ourTeam",
    },
    {
      id: 6,
      item: "Careers",
      path: "/career",
    },
  ];

  return (
    <>
      <div className="  h-20 flex justify-between px-3 sm:px-10 items-center bg-white shadow-md ">
        <div>
          <Link
            to={""}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <img src={xbi4_logo} alt="" />
          </Link>
        </div>
        <div className="hidden md:flex sm:gap-4 lg:gap-8">
          <ul className="flex items-center md:gap-6 cursor-pointer">
            {items.map(({ id, item, path }) => (
              <Link
                to={path}
                key={id}
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                <li className="hover:scale-125">{item}</li>
              </Link>
            ))}
          </ul>

          <Link to="contactUs">
            <Button className="bg-blue-500 text-white p-3 rounded-md">
              Contact us
            </Button>
          </Link>
        </div>
        <div className="md:hidden z-50" onClick={() => setNav(!nav)}>
          {nav ? (
            <FaTimes size={30} className="text-white" />
          ) : (
            <FaBars size={30} />
          )}
        </div>
        {nav && (
          <ul
            className={`fixed top-0 right-0 z-40 pt-5 pl-5 w-60 h-screen bg-black text-white  ${
              nav ? "animate-slide-in-left" : ""
            } `}
          >
            {items.map(({ id, item, path }) => (
              <Link to={path}>
                <li key={id} className="text-xl  p-3 hover:text-gray-500">
                  {item}
                </li>
              </Link>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default Navbar;
