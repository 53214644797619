import React from "react";
import Video from "../../components/Videos/Video.mp4";

const HeroComponent = () => {
  return (
    <div className=" relative">
      <div className="">
        <video className="" autoPlay muted loop>
          <source src={Video} type="video/mp4"></source>
        </video>
        <div className=" absolute bottom-0 left-0 right-0   bg-opacity-50 text-white text-center p-2">
          <p className="text-sm sm:text-3xl  md:font-medium lg:font-semibold font-roboto  ">
            "Your Journey, Our Expertise: Together We Thrive."{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default HeroComponent;
