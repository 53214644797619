import { POST } from "../../helper/restFunctions";

const jobApplication = async (data: any) => {
  const path = `/join_us_page`;
  console.log("data to post function", data);
  console.log(POST, "function function reponse");
  return await POST(path, data)
    .then((result) => {
      console.log(result[0], "0 index-----------");
      console.log(result[1], "1 yindex-----------");

      return result[1];
    })
    .catch((err) => {
      return err;
    });
};

export default jobApplication;
