import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "../layout/layout";
import Home from "../pages/Home";
import OurTeam from "../pages/OurTeam";
import Service from "../pages/Service";
import OurProducts from "../pages/OurProducts";
import Contact from "../pages/contact/Contact";
import AboutUs from "../pages/aboutus/AboutUs";
import Career from "../pages/careers/Career";
import JobDetails from "../pages/careers/JobDetails";
import Application from "../pages/careers/Application";
import Blog from "../pages/Blog/Blog";

function RouterPage() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="ourTeam" element={<OurTeam />} />
            <Route path="aboutUs" element={<AboutUs />} />
            <Route path="service" element={<Service />} />
            <Route path="ourProducts" element={<OurProducts />} />
            <Route path="contactUs" element={<Contact />} />
            <Route path="career">
              <Route index element={<Career />} />
              <Route path=":looking_for_id" element={<JobDetails />} />
            </Route>

            <Route path="application" element={<Application />} />
            <Route path="blog/:id" element={<Blog />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default RouterPage;
