import ManagementConsultant from "../assets/ServicePageImg/ManagementCounsltant.png";
import ProductDevelopemet from "../assets/ServicePageImg/ProductDevelopment.png";
import Creatives from "../assets/ServicePageImg/Creatives&Media.png";
import MarketResearch from "../assets/ServicePageImg/MarketResearch.png";
import Analytics from "../assets/ServicePageImg/Analytics.png";
import MarketingService from "../assets/ServicePageImg/MarketingService.png";

import {Management,Marketing,Research,Analytic,Creative,Development} from "../assets/index"
export const OurServiceComp = [
  {
    title: "Management Consultancy",
    description: `Elevate your business to its fullest potential with our premier Management Consultancy services.
     At XBI4, we are dedicated to guiding organizations towards sustainable success through strategic insights, 
     operational excellence, and innovative solutions. Our seasoned consultants, armed with years of industry experience, 
     collaborate closely with your team to craft tailored strategies that address your unique challenges and opportunities. 
     Whether you're seeking to optimize processes, navigate change, or drive growth, our holistic approach ensures you stay ahead of the competition. 
     Join hands with us and unlock the full spectrum of your organization's potential – together, we'll shape a brighter future for your business.`,
    btnName: "Join Us",
    imgUrlPath: ManagementConsultant,
  },
  {
    title: "Product Development",
    description: `Welcome to XBI4, where we excel in the art of Product Development. Our expertise lies in turning your visionary concepts into tangible, market-leading products. With a blend of creativity, innovation, and rigorous processes, we bring your ideas to life. Our multidisciplinary team of product development experts works tirelessly to ensure that your product not only meets but exceeds your expectations. From concept ideation and design to prototyping and production, we manage every aspect of the development cycle. At XBI4, we don't just create products; we craft success stories that resonate with your target audience and drive growth. Come together with us to kickstart a journey towards innovation and outstanding results, where your ideas materialize into concrete solutions..`,
    btnName: "Join Us",
    imgUrlPath: ProductDevelopemet,
  },
  {
    title: "Creatives and Media",
    description: `Meet our exceptional Designing Team Digi Sparkz at XBI4, the creative engine behind crafting remarkable solutions. Our designers are more than just talented; they are passionate visionaries who breathe life into concepts. With diverse expertise spanning graphic design, UX/UI, and product design, they form a dynamic force dedicated to elevating your brand and user experience. From concept sketches to pixel-perfect prototypes, our team meticulously crafts every detail to ensure excellence in design. We believe that exceptional design is not just about aesthetics; it's about creating memorable experiences that resonate with your audience. Partner with us and allow our design maestros to turn your ideas into captivating tangible experiences.`,
    btnName: "Join Us",
    imgUrlPath: Creatives,
  },
  {
    title: "Market Research",
    description: `Introducing our Market Research Team at XBI4, a group of dedicated professionals with a relentless curiosity for understanding markets and consumer behaviour. With a wealth of experience and a data-driven approach, our team dives deep into the intricacies of your industry to uncover valuable insights. By collaborating closely with our clients, we transform raw data into actionable strategies that drive informed decision-making. We understand that knowledge is power, and our Market Research Team is here to empower your business with the insights needed to stay ahead in the ever-evolving marketplace. Join forces with us and let our experts be your compass in navigating the complex landscape of market dynamics`,
    btnName: "Join Us",
    imgUrlPath: MarketResearch,
  },
  {
    title: "Analytics",
    description: `Meet the brilliant minds of our Analytics Team at XBI4, where data is transformed into actionable intelligence. Our team of seasoned data scientists and analysts possesses a deep understanding of the power of data-driven decision-making. We are committed to unravelling the hidden patterns, trends, and opportunities within your organization's data landscape. With a focus on predictive analytics, business intelligence, and data visualization, we turn raw information into strategic insights that fuel your success. Whether you need to optimize operations, enhance customer experiences, or drive growth, our Analytics Team is here to guide you with precision and expertise.`,
    btnName: "Join Us",
    imgUrlPath: Analytics,
  },
  {
    title: "Marketing Service",
    description: `Elevate your brand and achieve marketing excellence with our comprehensive Marketing Services at XBI4. Our seasoned team of marketing experts is committed to unlocking the full potential of your business in the digital age. We offer a wide range of services, including strategic marketing planning, digital marketing, content creation, SEO optimization, and social media management. Whether you're a startup looking to establish a strong online presence or an established enterprise aiming to boost your market share, our tailored solutions are designed to meet your unique objectives. At XBI4, we blend creativity with data-driven insights to craft compelling campaigns that resonate with your target audience and drive tangible results. Join us in shaping a brighter future for your brand through our innovative Marketing Services.`,
    btnName: "Join Us",
    imgUrlPath: MarketingService,
  },
];

export const OurServiceDetails=[
  {
    imgPath:Management,
    title: "Management Consultancy",
    description:
      "Elevate your business to its fullest potential with our premier Management Consultancy services.",
  },
    {
    imgPath:Development,
    title: "Product Development",
   description:
      "Welcome to XBI4, where we excel in the art of Product Development. Our expertise lies in turning your visionary concepts into tangible, market-leading products.",
  },
    {
    imgPath:Creative,
    title: "Creatives and Media",
    description:
      "Meet our exceptional Designing Team Digi Sparkz at XBI4, the creative engine behind crafting remarkable solutions",
  },
    {
    imgPath:Research,
    title: "Market Research",
   description:
      "Introducing our Market Research Team at XBI4, a group of dedicated professionals with a relentless curiosity for understanding markets and consumer behaviour.",
  },
    {
    imgPath:Analytic,
    title: "Analytics",
     description:
      "Meet the brilliant minds of our Analytics Team at XBI4, where data is transformed into actionable intelligence.",
  },
    {
    imgPath:Marketing,
    title: "Marketing Service",
     description:
      "Elevate your brand and achieve marketing excellence with our comprehensive Marketing Services at XBI4.",
  }
]