import React from "react";
import AboutContent from "./AboutContent";
import Vision from "../aboutus/Vision";
import CardContent from "./CardContent";
import Technology from "../aboutus/Technology";

const AboutUs = () => {
  return (
    <div className="">
      <AboutContent />
      <Vision />
      <CardContent />
      <Technology />
    </div>
  );
};

export default AboutUs;
