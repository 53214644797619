import HasibProfile from "../../../assets/HASIB.png";
import VinothProfile from "../../../assets/VSHIV.png";
import SubramaniProfile from "../../../assets/Subbhu.png";
import MukilProfile from "../../../assets/MukilArasi.png";
import AshfakProfile from '../../../assets/Ashfak.png'
import VinothKumarProfile from '../../../assets/vinoth.png'

export const productTeamProfile = [
  { id: 1, title: "Subramaniyan M", imgUrl: SubramaniProfile },
  { id: 2, title: "Vinoth Siva M", imgUrl: VinothProfile },
  { id: 3, title: "Abdul Hasib", imgUrl: HasibProfile },
  { id: 4, title: "Mukil Arasi M", imgUrl: MukilProfile },
  { id: 5, title: "Vinoth Kumar R", imgUrl: VinothKumarProfile },
  { id: 6, title: "Ashfak Ahamed", imgUrl: AshfakProfile },
];
