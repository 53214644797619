import React from "react";
import CategoryTeam from "../CategoryTeam";
import { MarketingTeamProfile } from "./MarketingTeamImg";

const MarketingTeam = () => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-4 text-center gap-11">
      {MarketingTeamProfile.map((category: any) => (
        <CategoryTeam key={category.id} ourTeam={category} />
      ))}
    </div>
  );
};

export default MarketingTeam;
