import React, { useEffect } from "react";
import PageCardImg from "../components/PageCardimg";
import { OurServiceComp } from "../Data/OurServiceData";
import servieStyle from "./ServiceStyle.module.css";
import { useSearchParams } from "react-router-dom";

const ss = servieStyle;

const Service = () => {
  const [searchParam] = useSearchParams();
  const value = searchParam.get("item");
  console.log(value);
  useEffect(() => {
    // Ensure there is an item in the URL parameters and the component is mounted
    if (value) {
      // Attempt to find the element with the given ID
      const element: any = document.getElementById(value);

      // If the element exists, scroll to it
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        // Additional scroll to adjust the position with an offset
        const elementRect = element.getBoundingClientRect();
        const absoluteElementTop = elementRect.top + window.pageYOffset;
        const middle = absoluteElementTop - 100; // Adjust the offset here
        window.scrollTo({ top: middle, behavior: "smooth" });
      }
    }
  }, [value]);
  const titleStyle = "text-gray-900 text-3xl font-semibold  ";

  const descriptionStyle = " text-gray-500 text-lg  font-normal text-justify ";

  const imgUrlStyle = " w-[350px] h-[350px]  ";
  return (
    <div>
      <div>
        <h1 className="animate-text bg-gradient-to-l from-sky-800 via-indigo-900 to-pink-800  bg-clip-text text-transparent  font-bold text-4xl p-4">
          {" "}
          Our Service
        </h1>
      </div>
      <div className="">
        {OurServiceComp.map((value: any, index) => (
          <div key={index} className="m-10 " id={value.title}>
            <PageCardImg
              title={value.title}
              conStyle="sm:w-[50%]"
              description={value.description}
              titleStyle={titleStyle}
              descriptionStyle={descriptionStyle}
              imgUrlPath={value.imgUrlPath}
              imgUrlStyle={imgUrlStyle}
              btnName={value.btnName}
              btnStyle="text-white"
              btnPath="/contactUs"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Service;
