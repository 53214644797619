import React from "react";
import { Tech_img } from "../../assets";

function Tech() {
  return (
    <div className="">
      <div className="w-full px-4 py-6">
      <div className="text-gray-900 font-roboto font-semibold text-2xl md:text-3xl lg:text-2xl ">Technologies We Use</div>
      <div className="border-b border-gray-300 my-4"></div>
      </div>
      <div className="flex justify-center items-center">
      <img className=" p-5 md:p-10 lg:px-40 xl:w-11/12 " src={Tech_img} alt=""></img>
      </div>
    </div>
  );
}

export default Tech;
