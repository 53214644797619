import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-scroll";

const Company = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-4">
      <p
        className="cursor-pointer"
        onClick={() => {
          window.scrollTo(0, 0);
          navigate("/aboutUs");
        }}
      >
        About
      </p>
      <Link to="contactUs" offset={-80}>
        <p className="cursor-pointer">Contact</p>
      </Link>
      {/* <p className="cursor-pointer">Blogs</p> */}
    </div>
  );
};

export default Company;
