import ConsdroidImg from "../assets/ProductPageImg/consdroid.png";
import TrupropsImg from "../assets/ProductPageImg/truprops.png";
import iSAImg from "../assets/ProductPageImg/Isa.png";
import FilmEazyImg from "../assets/ProductPageImg/Filmeazy.png";
import RivaziaImg from "../assets/ProductPageImg/Rivazia.png";
import TalkyTalks from "../assets/ProductPageImg/Talky.png";

export const ProductsData = [
  {
    id: 1,
    productId:"consdroid",
    title: "Consdroid",
    titleDesc: `Consdroid is a comprehensive construction management tool that streamlines project planning, scheduling, and collaboration for efficient construction operations.`,
    feature: "Features",
    featureDesc: [
      "Files: It is used to store and manage the project files.",
      "Inventory: It helps to track and manage stock materials.",
      "Member: To add and manage team members in the project.",
      "Site plan: It includes the 2D and 3D estimation of the CAD designs.",
      " Task: It is used to assign specific tasks to team members.",
      "Accounts: Users enables to maintain and track transactions.",
    ],
    imgUrl: ConsdroidImg,
  },
  {
    id: 2,
    title: "Truprops",
      productId:"truprops",
    titleDesc: `Truprops, the comprehensive property listing app, offers a one-stop platform for buying, selling, renting, and finding tenants, simplifying real estate transactions with a wide range of options and convenience. `,
    feature: "Features",
    featureDesc: [
      "Extensive Property Listings: Access a wide range of properties, from apartments to houses, catering to various budgets, locations, and preferences.",
      "Expert Assistance: We understand that real estate decisions can be complex, and you may have questions or require guidance.",
      "We're here to assist you every step of the way.",
      "Advanced Search and Filters: Refine your search with advanced filters like location and property type. Truprops makes it simple to find the perfect property that matches your preferences.",
      "User-Friendly Interface: Enjoy a smooth and intuitive experience, allowing you to navigate the software effortlessly.",
    ],
    imgUrl: TrupropsImg,
  },
  {
    id: 3,
    title: "iSA",
      productId:"isa",
    titleDesc:
      "iSA, offers drivers substantial earnings and users’ great savings, making it the ultimate choice for cost-effective and lucrative transportation solutions.",
    feature: "Features",
    featureDesc: [
      "Booking rides - Book rides effortlessly with our user-friendly app, ensuring quick access to reliable transportation, providing seamless travel convenience.",
      "Virtual travel desk - Easily schedule and track rides for employees and guests, streamlining the process for convenience and efficiency.",
      "Emergency alert - Prioritizing safety with support and emergency contacts for users and drivers, offering valuable assistance to our driver community.",
      "Reimbursement management - Streamline employee reimbursements for corporate admins through our platform, making management efficient and hassle-free.",
    ],
    imgUrl: iSAImg,
  },
  {
    id: 4,
    title: "Filmeazy",
      productId:"filmeazy",
    titleDesc:
      "Filmeazy, the ultimate film management app, simplifies every aspect of production, from scriptwriting to post-production, ensuring a seamless and efficient filmmaking process. ",
    feature: "Features",
    featureDesc: [
      "Script – Import script scenes related to the story, events, etc…",
      "Script Breakdown – It includes a breakdown of the scenes to be filmed on a specific day, including scene numbers, and descriptions.",
      "Contact – Users can manage and maintain the contact lists.",
      "Calendar – It allows for the scheduling or blocking the specific shoot dates for scenes, locations, and cast availability.",
      "Schedule – The schedule assists in managing and allocating resources such as equipment, props, costumes, and locations.",
      "Call sheet – The call sheet includes a detailed schedule for the day, specifying the call times, shoot locations, and scenes to be filmed.",
      "Checklist – The checklist helps manage and track the necessary equipment and gear needed for production, including cameras, lighting equipment, and sound equipment.",
    ],
    imgUrl: FilmEazyImg,
  },
  {
    id: 5,
    title: "Rivazia",
      productId:"rivazia",
    titleDesc:
      "At Rivazia, we are committed to amplifying every voice, empowering individuals like you to shape the world around us.Our platform offers an array of powerful features.",
    feature: "Features",
    featureDesc: [
      "Survey Creation: Effortlessly create, distribute, and analyze surveys to gather valuable insights and feedback from your audience.",
      "Robot Logic: Harness the power of automation to streamline tasks and decision-making, driven by predefined rules and conditions.",
      "Shareable Reports: Seamlessly share and collaborate on reports with robust access controls and real-time updates, ensuring efficient teamwork.",
      "Graphical Representation: Transform data into compelling visuals, using charts, graphs, and other graphical elements for enhanced understanding and communication.",
      "Panel Management: Simplify the organization, recruitment, and maintenance of survey or research panels, ensuring precision in data collection from your target participants.",
    ],
    imgUrl: RivaziaImg,
  },
  {
    id: 6,
    title: "Talkytalks",
      productId:"talkytalks",
    titleDesc:
      "TalkyTalks, the film-centric social media app, connects industry professionals, fans, and enthusiasts for lively discussions, exclusive content, and networking opportunities, making it a must-have for anyone passionate about movies.",
    feature: "Features",
    featureDesc: [
      " Portfolio- You can upload your portfolio, which can be seen by recruiters looking to hire you.",
      " Bioscope - A way to create and discover short, entertaining videos on talkytalks.",
      "Jobs - You can post jobs as well as save and apply for the positions.",
      "Ads - Users can post ads on the website.",
    ],
    imgUrl: TalkyTalks,
  },
];
