import { Rivazia, Consdroid,  Truprops } from "../assets/index";
interface ProjectDetails {
  imgpath: string;

  conStyle: string;
  title: string;
  description: string;
}
const details: ProjectDetails[] = [
    {
      imgpath: Rivazia,

      conStyle: "max-w-lg ",
      title: "Rivazia",
      description:
        "At Rivazia, we are committed to amplifying every voice, empowering individuals like you to shape the world around us.Our platform offers an array of powerful features.",
    },
    {
      imgpath: Consdroid,

      conStyle: "max-w-lg",
      title: "Consdroid",
      description:
        "Consdroid is a comprehensive construction management tool that streamlines project planning, scheduling, and collaboration for efficient construction operations.",
    },
    {
      imgpath: Truprops,

      conStyle: "max-w-lg",
      title: "Truprops",
      description:
        "Truprops, the comprehensive property listing app, offers a one-stop platform for buying, selling, renting, and finding tenants, simplifying real estate transactions with a wide range of options and convenience. ",
    },
  ];
  export default details