import React, { MouseEvent, ReactNode, HTMLProps } from "react";
import { Link } from "react-router-dom";
import { IconType } from "react-icons";
import { cn } from "../../lib/utils";

// cn is used for merging the tailwind classes is an utility function
interface CustomButtonProps extends HTMLProps<HTMLButtonElement> {
  children?: ReactNode;
  to?: any;
  cursorPointer?: boolean;
  text?: string;
  type?: "button" | "submit" | "reset";
  twMergeClx?: string;
  className?: string;
}

const Button: React.FC<CustomButtonProps> = ({
  children,
  onClick,
  to,
  cursorPointer = true,
  className = "",
  ...restProps
}) => {
  const buttonProps = {
    onClick,
    style: { cursor: cursorPointer ? "pointer" : "default" },
    ...restProps,
  };
  const buttonBaseStyle = {
    style: "flex items-center justify-center",
  };
  return to ? (
    <Link to={to} className={cn(`${buttonBaseStyle.style}`, className)}>
      {/* first argument default style,second argument conditonl styles third argument props style */}
      <button
        {...buttonProps}
        className={cn(`${buttonBaseStyle.style}`, className)}
      >
        {children}
      </button>
    </Link>
  ) : (
    <button
      {...buttonProps}
      className={cn(`${buttonBaseStyle.style}`, className)}
    >
      {children}
    </button>
  );
};

export default Button;
