const sendEmail = async (formData: any) => {
  const response = await fetch(
    "https://7ik9s2k445.execute-api.ap-south-1.amazonaws.com/sendemail",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: formData.name,
        message: formData.text,
        recipient: formData.email,
        html_body: `<html>
        <head>
            <style>
                body {{
                    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                    margin: 0;
                    padding: 0;
                    background-color: #f8f8f8;
                }}
                .container {{
                    width: 60%;
                    margin: auto;
                    background-color: #ffffff;
                    padding: 40px;
                    border-radius: 8px;
                    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
                    margin-top: 50px;
                    margin-bottom: 50px;
                    overflow: hidden;
                    text-align: left;
                }}
                .header {{
                    background-color: #fafafa;
                    color: #ffffff;
                    text-align: center;
                    padding: 20px;
                    border-radius: 8px 8px 0 0;
                    margin-bottom: 20px;
                }}
                .header img {{
                    width: 70%;
                    border-radius: 50%;
                    margin-bottom: 10px;
                }}
                .footer {{
                    background-color: #fafafa;
                    color: #ffffff;
                    text-align: center;
                    padding: 10px;
                    border-radius: 0 0 8px 8px;
                    margin-top: 20px;
                }}
                h1, h3 {{
                    color: #4fa94d;
                    margin-bottom: 10px;
                }}
                p {{
                    color: #333333;
                    line-height: 1.6;
                    margin: 10px 0;
                }}
                a {{
                    color: #3498db;
                }}
            </style>
        </head>
        <body>
            <div class="container">
            
                <p>We are delighted to inform you that a potential talent has reached out. Below are the details:</p>
                <p><strong>Name:</strong> ${
                  formData.first_name + " " + formData.last_name
                }</p>
                <p><strong>Phone:</strong> ${formData.email}</p>
                <p><strong>Email:</strong> ${formData.email}</p>
                <p><strong>Experience:</strong> ${formData.experience}</p>
                <p><strong>Qualification:</strong> ${formData.degree}</p>
                <p><strong>SSLC:</strong> ${formData.X_mark}</p>
                <p><strong>HSC:</strong> ${formData.XII_mark}</p>
                <p>Best regards,</p>
                <p>XBI4</p>
                <div class="footer">
                    <p>This is an automated message. Please do not reply directly to this email.</p>
                </div>
            </div>
        </body>
    </html>`,
        to: "rajanm@xbi4.com",
      }),
    }
  );
  return response;
};

export default sendEmail;
