import React from "react";

interface LineProps {
  color?: string;
  width?: string;
}

const Line: React.FC<LineProps> = ({ color = "border-gray-400", width = "" }) => {
  return <hr className={`border border-${color} ${width}`} />;
};

export default Line;
