import React from "react";
import Button from "./UI/Button";

interface PageCardProps {
  title: string;
  description: string;
  titleStyle?: string;
  descriptionStyle?: string;
  conStyle?: string;
  btnName: string;
  btnStyle?: string;
  showButton?: boolean;
  imgUrlPath: string;
  imgUrlStyle?: string;
  btnPath?: string;
}
const PageCardImg: React.FC<PageCardProps> = ({
  title,
  description,
  titleStyle,
  descriptionStyle,
  conStyle,
  btnName,
  btnStyle,
  showButton = true,
  imgUrlPath,
  imgUrlStyle,
  btnPath,
}) => {
  return (
    <div className="flex flex-col sm:flex-row justify-around items-center gap-14 ">
      <div className={`flex flex-col gap-6 ${conStyle}`}>
        <h3 className={titleStyle}>{title}</h3>
        <p className={descriptionStyle}>{description}</p>
        {showButton && ( // Conditionally render the button
          <Button
            to={btnPath}
            className={`bg-blue-500 w-32 px-3 py-2 rounded-md  ${btnStyle}`}
          >
            {btnName}
          </Button>
        )}
      </div>

      <img className={imgUrlStyle} src={imgUrlPath} />
    </div>
  );
};

export default PageCardImg;
