import { GET } from "../../helper/restFunctions";

const getJobById = async (looking_for_job_id:any) => { 
  const path = `/looking_for_job/${looking_for_job_id}`;
  try {
    const result = await GET(path);
    console.log("GetjobByIddd", result);
    return result;
  } catch (err) {
    console.error("Error fetching job by ID:", err);
    throw err;
  }
};

export default getJobById;
