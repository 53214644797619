import React from "react";

import "./App.css";
import RouterPage from "./router/RouterPage";
import Footer from "./layout/footer/Footer";

function App() {
  return (
    <div>
      <RouterPage />
    </div>
  );
}

export default App;
