import React from 'react'
import { IoMail } from 'react-icons/io5'

const GetInTouch = () => {
    const email = 'info@xbi4.com';

    const handleEmailClick = () => {
      window.location.href = `mailto:${email}`;
    };
  return (
    <div>
      Get In Touch :
      <p className="flex items-center gap-2" onClick={handleEmailClick} style={{ cursor: 'pointer' }}>
        <span>
          <IoMail />
        </span>
        {email}
      </p>
    </div>
  )
}

export default GetInTouch
