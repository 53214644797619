import React, { FC, useState } from "react";
import InputFeild from "./InputField";
import { CiSearch } from "react-icons/ci";
interface ISearchBarProps {
  onSearch?: (value: string) => void;
  placeHolder: string;
  conStyle?: string;
  value?: string | number | readonly string[];
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const SearchBar: FC<ISearchBarProps> = ({
  onSearch,
  conStyle,
  placeHolder,
  value,
  onChange,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className={`relative  ${conStyle}`}>
      <InputFeild
        type="text"
        style={{
          backgroundColor: "white",
          width: "100%",
          outline: isFocused ? "none" : "0",

          fontSize: "1rem",
          flexGrow: "1",
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        value={value}
        onChange={onChange}
        placeholder={placeHolder}
      />
      <div className="absolute top-5 right-5 ">
        <CiSearch size={20} className="text-gray-800 " />
      </div>
    </div>
  );
};

export default SearchBar;
