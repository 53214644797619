import React, { useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import BlogData from "../../Data/BlogData";
import Button from "../../components/UI/Button";
import { BsArrowLeftShort } from "react-icons/bs";

const Blog = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Retrieve the scroll position from the location state
  const scrollPosition = location.state?.scrollPosition || 0;

  useEffect(() => {
    // Wait until the current execution context is complete, and then apply the scroll position.
    // This ensures that the DOM has been painted at least once before we try to change the scroll position.
    setTimeout(() => {
      window.scrollTo(0, scrollPosition);
    }, 0);

    // There's no cleanup function here because we're not setting up any subscriptions or event listeners
  }, [scrollPosition]); // This effect depends on the scrollPosition, so it re-runs if scrollPosition changes

  const blogPost = BlogData.find((post) => post.id.toString() === id);

  if (!blogPost) {
    return <div>Blog post not found</div>;
  }

  return (
    <div>
      <div className="flex justify-start items-center">
        <Button onClick={() => navigate(-1)}>
          <BsArrowLeftShort />
          Previous
        </Button>
      </div>
      <div key={blogPost.id} className="max-w-xl mx-auto flex flex-col gap-12">
        <h1 className="text-2xl text-black font-bold">{blogPost.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: blogPost.description }} />
      </div>
    </div>
  );
};

export default Blog;
