import React from "react";
import ProjectCard from "../../components/ProjectCard";
import { HeroImage } from "../../assets/index";

const Dashboard = () => {
  const title = "Empowering Progress Through Innovation and Integrity";
  const description =
    "Welcome to XBI4 India Private Limited, where innovation and integrity converge to shape a brighter future. As a dynamic management consultancy firm, we are driven by a relentless commitment to transforming businesses through visionary solutions. Our journey is characterized by a decade-long dedication to pioneering creativity, collaborating with clients, and developing groundbreaking products. At XBI4, we believe in the power of knowledge, the strength of innovation, and the value of lasting partnerships. Join us on our quest to empower progress, one idea at a time. ";

  return (
    <div className="max-w-screen-xl mx-auto">
      <div className="flex flex-col-reverse sm:flex-row gap-3 justify-center items-center ">
        <div className="p-10">
          <ProjectCard
            to="contactUs"
            title={title}
            titleStyle="text-4xl font-bold"
            description={description}
            descriptionStyle="text-justify"
            btnName="Contact us"
            btnStyle="w-32 p-3 text-white"
          />
        </div>
        <img className="sm:w-[50%]" src={HeroImage} alt="" />
      </div>
    </div>
  );
};

export default Dashboard;
