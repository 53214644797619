import React from "react";
import { arrow } from "../../assets";

function Vision() {
  return (
    <div className="bg-gray-900 text-white  flex  items-center  sm:justify-between md:p-10 lg:p-0 pt-2 sm:p-0">
      <div className=" pr-0 sm:w-1/2 sm:pr-6">
        <img className="w-96" src={arrow} alt=""></img>
      </div>
      <div className="sm:w-3/4  ">
        <h1 className="text-white  text-3xl sm:mb-5 ">Vision</h1>
        <div className="max-w-xl  text-justify p-2 sm:p-0">
          Our vision at XBI4 India Private Limited is to lead the way in
          fostering sustainable growth and innovation for businesses worldwide.
          We aspire to be the catalyst that empowers organizations to adapt,
          thrive, and prosper in an ever-evolving business landscape. Through
          cutting-edge consultancy services, revolutionary product offerings,
          and unwavering dedication to our clients, we aim to drive positive
          change and set new industry standards.
        </div>
      </div>
    </div>
  );
}

export default Vision;
