import React, { useEffect, useState } from "react";
import { Women1 } from "../../assets";
import ProjectCard from "../../components/ProjectCard";

const WhatWeDo = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const description =
    "At XBI4, we excel in harnessing the power of IT to drive your business forward. As a trusted consultant management company, we provide the expertise, talent, and solutions you need to navigate the ever-evolving technology landscape.";

  // Update windowWidth when the window is resized
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ backgroundColor: "#222222", color: "white" }}>
      <div className="py-20 max-w-screen-xl mx-auto relative">
        <div className="flex   items-center">
          <img
            className={`w-[50%] ${windowWidth < 728 ? "hidden" : ""}`}
            src={Women1}
            alt=""
          />
          <ProjectCard
            title="What we do ?"
            titleStyle="text-2xl font-bold"
            description={description}
            descriptionStyle="text-gray-200 text-justify"
            showButton={false}
            conStyle="p-4 "
          />
        </div>
        <div
          className={`w-[100px] h-[100px] rounded-full bg-slate-800 absolute right-0 bottom-8 ${
            windowWidth < 728 ? "hidden" : ""
          }`}
        ></div>
      </div>
    </div>
  );
};

export default WhatWeDo;
