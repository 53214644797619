  export const whyDetails = [
    {
      heading: "Client-Centric Approach",
      description:
        "We put you first, tailoring our strategies to your unique needs and goals, ensuring your success is our top priority. ",
    },
    {
      heading: "Deep Customer Empathy",
      description:
        "We understand your challenges and aspirations on a profound level, allowing us to create solutions that resonate and drive real impact. ",
    },
    {
      heading: "Ruthless Prioritization & Execution",
      description:
        "We focus on what matters most, relentlessly prioritizing your objectives to deliver results with precision and efficiency. ",
    },
    {
      heading: "Industry Category Breadth",
      description:
        "Our expertise spans across diverse industries, offering you a wealth of knowledge and insights that can revolutionize your business, no matter your sector.",
    },
  ];

  export const Details = [
    {
      title: "The Power of Innovative Consultancy",
      description: "Welcome to XBI4 India Private Limited, where innovative consultancy isn't just a service; it's a transformative journey. In today's rapidly evolving business landscape, embracing change is essential. Companies face a multitude of challenges and opportunities, making innovation a necessity for staying competitive and relevant. At XBI4, we understand the vital role of innovation in business transformation, and it's at the core of our mission. ",
    },
    {
      title: "DigiSparkz Unleashed",
      description: "Creativity is a potent catalyst that drives the world of compelling branding and design. At XBI4, we take this creative endeavour to new heights through our dedicated creative team, DigiSparkz. Prepare to embark on a journey through the world of creative excellence, where imagination knows no bounds, and innovation is our guiding star. ",
    },
    {
      title: " A Day in the Life at XBI4",
      description: "At XBI4 India Private Limited, innovation isn't merely a part of our work; it's woven into the very fabric of our existence. It's not a buzzword, but a way of life. We invite you to step into our world, where every day is a testament to our dedication to innovation, collaboration, and an unwavering commitment to our clients.  ",
    },
  ];