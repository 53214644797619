import React, { ChangeEvent } from "react";

interface DropdownProps {
  label: string;
  options: string[];
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  value: string;
  placeholder: string;
  styles?: React.CSSProperties;
  containerClassName?: string;
  selectClassName?: string;
  className?: string;
}
const Dropdown: React.FC<DropdownProps> = ({
  label,
  options,
  onChange,
  value,
  placeholder,
  styles = {},
  containerClassName = "",
  selectClassName = "",
}) => {
  return (
    <div className={`relative pt-4 ${containerClassName}`}>
      <label className="font-medium  md:text-2xl lg:text-lg" htmlFor={label}>
        {label}
      </label>
      <select
        id={label}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        style={styles}
        className={`mt-1 block w-full bg-white border border-blue-200 focus:outline-none focus:border-blue-400 focus:bg-white hover:shadow-md transition duration-300 ease-in-out px-2 py-2 rounded-md  md:p-4 lg:p-2  ${selectClassName}`}
      >
        <option value="" className="text-gray-400">
          {`Select ${label}`}
        </option>
        {options.map((option: string, index: number) => (
          <option key={index} value={option} className="text-black">
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
