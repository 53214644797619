import React from "react";
import { MarketResearchTeamProfile } from "./MarketResearchProfile";
import CategoryTeam from "../CategoryTeam";

const MarketResearchTeam = () => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-4 text-center gap-11">
      {MarketResearchTeamProfile.map((category) => (
        <CategoryTeam key={category.id} ourTeam={category} />
      ))}
    </div>
  );
};

export default MarketResearchTeam;
