import React from "react";

interface ComingSoonProps {
  text: string;
  textStyle?: string;
  location: string;
  iconStyle?: string;
  conStyle?: string;
}

const ComingSoon: React.FC<ComingSoonProps> = ({
  text,
  textStyle,
  location,
  iconStyle,
  conStyle,
}) => {
  return (
    <div className="flex gap-2 items-center ">
      <div
        className={` px-4 py-1 animate-text bg-gradient-to-l from-sky-800 via-indigo-900 to-pink-800 rounded-r-full rounded-l-full text-white items-center gap-3${conStyle}`}
      >
        <p className={textStyle}>{text}</p>
      </div>
      <img src={location} className={iconStyle} alt="Icon" />
    </div>
  );
};

export default ComingSoon;
