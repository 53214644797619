import React from 'react';

interface TextAreaProps {
  id: string;
  label: string;
  type: string;
  placeholder: string;
  value?: string; 
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextArea: React.FC<TextAreaProps> = ({
  id,
  label,
  type,
  placeholder,
  value,
  onChange,
}) => {
  return (
    <div>
      <label htmlFor={id} className="block text-gray-700 mb-2 md:text-2xl lg:text-lg">
        {label}:
      </label>
      <textarea
        id={id}
        className="w-full h-1/2 p-2 border border-gray-300 rounded-lg text-lg focus:outline-none focus:border-blue-500"

        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default TextArea;
