import React from "react";
import { OurServiceDetails } from "../../Data/OurServiceData";
import Card from "../../components/Card";
import { BsArrowRightCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import { title } from "process";

interface ServiceDetail {
  imgPath: string;
  title: string;
  description: string;
}

const OurServices: React.FC = () => {
  return (
    <div className="max-w-screen-xl mx-auto">
      <div className="grid grid-flow-col-dense col-span-1 sm:grid-flow-row-dense sm:grid-cols-2 md:grid-cols-3 overflow-x-scroll gap-10 p-10 [&::-webkit-scrollbar]:hidden [-ms-overflow-style:'none'] [scrollbar-width:'none']">
        {OurServiceDetails.map((service: ServiceDetail, index: number) => (
          <div
            key={index}
            className="flex flex-col justify-between items-center gap-6 min-w-[250px] max-w-[350px] bg-gray-100 shadow-md p-5 rounded-xl"
          >
            <Card
              img={service.imgPath}
              title={service.title}
              description={service.description}
              descriptionStyle="tracking-tight text-justify"
              bodyStyle="flex flex-col items-center"
            />
            <Link to={`/service?item=${service.title.trim()}`}>
              <BsArrowRightCircle size={30} className="cursor-pointer" />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurServices;
