import { API_URL } from "../config";

export const POST = async (path?: string, data?: any, token?: string) => {
  console.log(
    `${API_URL}${path}`,
    "helllooo thereeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
  );
  const res = await fetch(`${API_URL}${path}`, {
    method: "POST",
    mode: "no-cors",
    body: JSON.stringify(data),
  })
    .then(async (res) => {
      console.log("Res inside the then method", res);
      const json = await res.json();
      console.log("Res after", res);
      return [res?.status, json];
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return [500, error];
    });
  return res;
};

// export const POST = async (path?: string, data?: any, token?: string) => {
//   try {
//     const response = await fetch(`${API_URL}${path}`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         // You can include the token here if needed
//         // Authorization: `Bearer ${token}`,
//       },
//       body: JSON.stringify(data),
//     });

//     if (!response.ok) {
//       // If response status is not in the range 200-299, it's considered an error
//       throw new Error(`Request failed with status ${response.status}`);
//     }

//     // Parse response body as JSON
//     const responseData = await response.json();

//     return [response.status, responseData];
//   } catch (error: any) {
//     // Specify type of error as 'any' or 'Error'
//     // If an error occurs during fetch or JSON parsing, return a 500 status
//     return [500, { error: error.message }];
//   }
// };

// // ------------------------------POST
interface patchProps {
  apiUri: string;
  data: Object;
  token?: string;
}
export const PATCH = async ({ apiUri, data, token }: patchProps) => {
  const res = await fetch(`${API_URL}${apiUri}`, {
    method: "PATCH",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "text/html",
    },
  })
    .then(async (res) => {
      const json = await res.json();
      return [res?.status, json];
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return [500, error];
    });
  return res;
};

export const DELETE = async (apiUri?: string, token?: string) => {
  const res = await fetch(`${API_URL}${apiUri}`, {
    method: "DELETE",
  })
    .then(async (res) => {
      const json = await res.json();
      return [res?.status, json];
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return [500, error];
    });
  return res;
};

// export const PUT = async (apiUri?:string, data?:any, token?:string) => {
//   const res = await fetch(`${API_URL}${apiUri}`, {
//     method: "PUT",
//     mode: "no-cors",
//     body: JSON.stringify(data),
//     headers: {
//       "Content-Type": "application/json",
//     },
//   })
//     .then((res) => {

//       // const json = await res.json()
//       // return [res?.status, json];
//     })
//     .catch((error) => {

//     });
//   return res;
// };

export const GET = async (
  apiUri?: string,
  signal?: AbortSignal,
  token?: string
) => {
  const endpoint = `${API_URL}${apiUri}`;

  const res = await fetch(endpoint, {
    method: "GET",
    mode: "cors",
    signal: signal,
  })
    .then(async (res) => {
      const json = await res.json();
      return [res?.status, json];
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return [500, error];
    });
  return res;
};
