import React from "react";
import Contact from "./contact/Contact";
import Project from "./product/Project";
import Footer from "../layout/footer/Footer";
import WhyUs from "./whyUs/WhyUs";
import OurServices from "./Services/OurServices";
import Dashboard from "./Dashboard/Dashboard";
import WhatWeDo from "./whatWeDo/WhatWeDo";

const Home = () => {
  return (
    <div>
      <Dashboard />
      <WhatWeDo />
      <OurServices />
      <Project />
      <WhyUs />
      <Contact />
      <Footer />
    </div>
  );
};

export default Home;
