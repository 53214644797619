import React from "react";
import HeroComponent from "./HeroComponent";
import JoinTeam from "./JoinTeam";
import JobCard from "./JobCard";

function Career() {
  return (
    <div>
      <HeroComponent />
      <JoinTeam />
      <JobCard />
    </div>
  );
}

export default Career;
