import React from "react";
import Button from "./UI/Button";
import { Link } from "react-scroll";

interface ProjectCardProps {
  title: string;
  description?: any;
  titleStyle?: string;
  descriptionStyle?: string;
  conStyle?: string;
  btnName?: string;
  btnStyle?: string;
  showButton?: boolean;
  to?: any;
  navigate?: boolean;
}

const ProjectCard: React.FC<ProjectCardProps> = ({
  title,
  titleStyle,
  description,
  descriptionStyle,
  btnName,
  conStyle,
  btnStyle,
  showButton = true,
  to,
  navigate = false,
}) => {
  const DescriptionComponent = typeof description === "string" ? "p" : "div";

  return (
    <div className={`flex flex-col gap-6 ${conStyle}`}>
      <h3 className={`${titleStyle}`}>{title}</h3>
      <DescriptionComponent className={descriptionStyle}>
        {description}
      </DescriptionComponent>
      {showButton && ( // Conditionally render the button
        <Link to={to} offset={-80}>
          <Button
            className={`bg-blue-500 w-24 px-4 py-1 rounded-md ${btnStyle}`}
          >
            {btnName}
          </Button>
        </Link>
      )}
      {navigate && ( // Conditionally render the button
        <Button
          to={to}
          className={`bg-blue-500 w-24 px-4 py-1 rounded-md ${btnStyle}`}
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          {btnName}
        </Button>
      )}
    </div>
  );
};
export default ProjectCard;
