import React from "react";
import { about_lady } from "../../assets";

function AboutContent() {
  return (
    <div className="">
      <div className="flex flex-col sm:flex-row justify-center items-center">
        <div className=" sm:w-[50%] flex flex-col gap-10">
          <h1 className=" custom-gradient-text  font-roboto font-black text-3xl  lg:text-3xl md:text-4xl animate-slide-in-left">
            About XBI4
          </h1>
          <h1 className="text-xl font-semibold">Our History</h1>
          <p className="font-roboto font-normal text-gray-700  text-justify ">
            XBI4 India Private Limited has a rich and illustrious history that
            spans over a decade. Founded in 2018, our journey began with a
            vision to revolutionize the management consultancy industry by
            infusing it with innovation, technology, and a commitment to
            excellence. Over the years, we've grown steadily, evolving into a
            trusted partner for businesses seeking transformative solutions. Our
            history is marked by a series of milestones, successful
            collaborations, and the development of groundbreaking products that
            have left a lasting impact on the markets we serve.
          </p>
        </div>

        <div className="flex ">
          <img className="   " src={about_lady} alt=""></img>
        </div>
      </div>
    </div>
  );
}

export default AboutContent;
