import React, { useState, useEffect } from "react";
import SearchBar from "../../components/UI/SearchBar";
import getJobTitle from "../../api/GET/getJobTitle";
import Button from "../../components/UI/Button";
import JobCardData from "../../Data/JobCardData";
import { useNavigate, Navigate, useParams } from "react-router-dom";
import Line from "../../components/UI/Line";

interface JobTitle {
  title: string;
  job_type: string;
  location: string;
  looking_for_id: any;
}

function JobCard() {
  const [jobTitles, setJobTitles] = useState<JobTitle[]>([]);

  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const jobData: any = await getJobTitle();
        setJobTitles(jobData[1]);
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchJob();
  }, []);

  const filteredData = jobTitles.filter((value) =>
    value.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  console.log("Filtered Data:", filteredData);

  const navigateJobDetails = (looking_for_id: any | undefined) => {
    console.log(looking_for_id);
    navigate(`/career/${looking_for_id}`);
  };

  return (
    <div className="pt-10 bg-gray-100 capitalize ">
      <div className=" px-10">
        <SearchBar
          placeHolder="Enter keyword"
          conStyle="md:w-[40%] md:ml-[30%]"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="flex justify-center pt-4">
        <hr className=" border  border-gray-300  lg:w-3/5" />
      </div>

      <div className=" ">
        {filteredData.map(
          ({ looking_for_id, location, job_type, title }, index) => (
            <div
              key={index}
              className=" flex sm:flex-col md:max-w-3xl 2xl:max-w-5xl 2xl:ml-48  p-3 relative "
            >
              <div className=" sm:block  items-center  ">
                <div className="flex-1 sm:flex-col break-words sm:float-left sm:mr-1 md:text-lg  lg:text-xl 2xl:text-xl font-black sm:font-semibold">
                  {title}
                </div>
                <div className="md:text-lg lg:text-xl 2xl:text-xl sm:font-medium ">
                  {"| " + job_type + "| " + location  }
                </div>
              </div>

              <div className="mt-2">
                <Button
                  className=" bg-blue-500 rounded sm:float-right absolute right-10 md:right-20 px-5 p-1 sm:bottom-12 text-white"
                  onClick={() => navigateJobDetails(looking_for_id)}
                >
                  Apply for job
                </Button>
              </div>
              <div className="py-4 ">
                <Line />
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default JobCard;
