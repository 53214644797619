import React, { Fragment } from "react";

interface CardProps {
  bodyStyle?: string;
  img: string;
  alt?: string;
  titleStyle?: string;
  title: string;
  descriptionStyle?: string;
  imgStyle?: string;
  description: string;
}

const Card: React.FC<CardProps> = ({
  bodyStyle,
  img,
  alt,
  titleStyle,
  imgStyle,
  title,
  descriptionStyle,
  description,
}) => {
  return (
    <Fragment>
      <div className={bodyStyle}>
        <img
          src={img}
          alt={alt}
          className={`rounded-full lg:w-2/4  2xl:w-auto w-2/5 ${imgStyle}`}
        />
        <div className="flex flex-col gap-4 items-center">
          <p className={`text-xl font-semibold ${titleStyle}`}>{title}</p>
          <p className={descriptionStyle}>{description}</p>
        </div>
      </div>
    </Fragment>
  );
};

export default Card;
