import React from "react";

interface ProjectImageCardProps {
  imgPath: string;
  imgStyle?: string;
  bgStyle?: string;
  conStyle?: string;
  showBackground?: boolean;
  imagePositionLeft?: boolean;
}

const ProjectImageCard: React.FC<ProjectImageCardProps> = ({
  imgPath,
  imgStyle,
  bgStyle,
  conStyle,
  showBackground = true,
  imagePositionLeft = true,
}) => {
  return (
    <div className={` ${conStyle}`}>
      {showBackground && (
        <div
          className={` opacity-80 rounded-md relative min-w-[300px] min-h-[300px] ${
            imagePositionLeft ? "ml-20" : ""
          }  ${bgStyle}`}
          style={{ backgroundColor: "#2E2D2D" }}
        >
          <img
            src={imgPath}
            alt=""
            className={`absolute rounded-lg top-4 object-contain ${
              imagePositionLeft ? "-left-10 top-10" : "-right-10 top-10"
            } max-h-[250px]  ${imgStyle}`}
          />
        </div>
      )}
      {!showBackground && (
        <div>
          <img src={imgPath} alt="" className={` rounded-md   ${imgStyle}`} />
        </div>
      )}
    </div>
  );
};

export default ProjectImageCard;
