import React from "react";
import InputField from "../../components/UI/InputField";
import Button from "../../components/UI/Button";
import man from "../../assets/man1.png";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import sendEmail from "../../utilities/sendEmail";

const Contact = () => {
  const schema = z.object({
    name: z
      .string()
      .min(4, { message: "The username must be 4 characters or more" }),
    email: z.string().email({
      message: "Invalid email. Please enter a valid email address",
    }),
    phoneNumber: z.string().min(10, { message: " Enter a valid phone number" }),
    text: z
      .string()
      .min(10, { message: "Must contain atleast 10 characters." }),
  });

  type FormInput = z.infer<typeof schema>;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInput>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: "",
      email: "",
      phoneNumber: "",
      text: "",
    },
  });

  const handleFormSubmit = async (formData: FormInput) => {
    try {
      const response = await sendEmail(formData);

      if (response.status === 200) {
        console.log("Email sent successfully");
      } else {
        console.error("Failed to send email");
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };
  return (
    <div id="contactUs" style={{ backgroundColor: "#222222" }}>
      <div className="max-w-screen-xl mx-auto py-10">
        <div className="mx-10">
          <h3 className="text-2xl text-white font-bold ">Let's talk</h3>
          <div className="flex justify-center items-center ">
            <div className="w-full">
              <form
                onSubmit={handleSubmit(handleFormSubmit)}
                className="md:w-[70%] p-10 flex flex-col gap-5"
              >
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <InputField
                      id="name"
                      labelstyle="text-white"
                      label="Name"
                      type="text"
                      placeholder="Enter a name"
                      {...field}
                    />
                  )}
                />
                {errors.name && (
                  <p className="text-red-500">* {`${errors.name.message}`}</p>
                )}
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <InputField
                      id="email"
                      label="Email"
                      labelstyle="text-white"
                      type="email"
                      placeholder="Enter an email"
                      {...field}
                    />
                  )}
                />
                {errors.email && (
                  <p className="text-red-500">* {`${errors.email.message}`}</p>
                )}

                <Controller
                  name="phoneNumber"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <InputField
                      id="phoneNumber"
                      labelstyle="text-white"
                      label="Mobile number"
                      type="number"
                      placeholder="Enter a phone number"
                      {...field}
                    />
                  )}
                />
                {errors.phoneNumber && (
                  <p className="text-red-500">* {errors.phoneNumber.message}</p>
                )}
                <Controller
                  name="text"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <InputField
                      id="message"
                      labelstyle="text-white"
                      label="Message"
                      type="textarea"
                      placeholder="Enter a message"
                      {...field}
                    />
                  )}
                />

                {errors.text && (
                  <p className="text-red-500">* {`${errors.text.message}`}</p>
                )}

                <Button className="p-3 bg-sky-600 w-full rounded-md">
                  Contact
                </Button>
              </form>
            </div>
            <img className="hidden sm:block w-[40%]" src={man} alt="man" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
