import React from "react";
import OurTeamLogo from "../assets/ConsultingManagementTools.png";
import DevelopmentTeam from "../components/CategoryTeam/DevelopmentTeam/DevelopmentTeam";
import CreativesTeam from "../components/CategoryTeam/CreativesTeam/CreativesTeam";
import MarketingTeam from "../components/CategoryTeam/MarketingTeam/MarketingTeam";
import TalentAcquisitions from "../components/CategoryTeam/TalentAcquisitionsTeam/TalentAcquisitions";
import AdminFinanceTeam from "../components/CategoryTeam/AdminandFinanceTeam/AdminFinanceTeam";
import AnalyticsTeam from "../components/CategoryTeam/AnalyticsTeam/AnalyticsTeam";
import MarketResearchTeam from "../components/CategoryTeam/MarketResearchTeam/MarketResearchTeam";

const OurTeam = () => {
  const OurTeamComponents = [
    { id: 1, title: "Development", component: <DevelopmentTeam /> },
    { id: 2, title: "Creatives", component: <CreativesTeam /> },
    { id: 3, title: "Marketing", component: <MarketingTeam /> },
    { id: 4, title: "Analytics", component: <AnalyticsTeam /> },
    { id: 5, title: "Market Research", component: <MarketResearchTeam /> },
  ];
  return (
    <div className="flex justify-center items-center flex-col">
      <div className="flex justify-center items-center p-3 py-11">
        <img src={OurTeamLogo} />
      </div>
      {OurTeamComponents.map(({ id, title, component }) => (
        <div className="p-10" key={id}>
          <h1 className="w-full text-gray-900 text-2xl font-bold  font-roboto  leading-10 break-words py-2">
            {title}
          </h1>
          {component}
        </div>
      ))}
      <div className="md:p-10 m-10 grid grid-cols-2 md:grid-cols-4 text-center gap-11 justify-center items-center md:break-words ">
        {/* <div className="flex flex-col">
          <h1 className="w-full text-gray-900 text-2xl font-bold  font-roboto  leading-10 break-words py-2">
            Talent Acquisitions
          </h1>
          <TalentAcquisitions />
        </div> */}
        <div className="flex flex-col items-center justify-center ">
          <h1 className="w-full text-gray-900 text-2xl font-bold  font-roboto  leading-10 break-words py-2">
            {" "}
            Admin & Finance
          </h1>
          <AdminFinanceTeam />
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
