import React from "react";
import { BiSolidPhoneCall } from "react-icons/bi";
import { MdLocationPin } from "react-icons/md";
import { IoMail } from "react-icons/io5";
const Reach = () => {
  return (
    <div className="flex flex-col">
    <div className="flex flex-col gap-2">
      Our Presence :
      <p className="flex items-center gap-2">
        <span>
          <MdLocationPin />
        </span>
        Panpozhi, Tenkasi TN - 627807
      </p>
      <div className="flex items-center gap-2 ">
        <span>
          <BiSolidPhoneCall />
        </span>
        +91 4633 210571
      </div>
    </div>
     <div className="flex flex-col gap-2">
      <br/>
     <p className="flex items-center gap-2">
       <span>
         <MdLocationPin />
       </span>
       Danbury CT -06810
     </p>
     <p className="flex items-center gap-2 ">
       <span>
         <BiSolidPhoneCall />
       </span>
       +1 203 744 9575
     </p>
   </div>
    </div>
  );
};

export default Reach;
