import React from "react";
import CategoryTeam from "../CategoryTeam";
import { AnalyticsTeamProfile } from "./AnalyticsTeamProfile";

const AnalyticsTeam = () => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-4 text-center gap-11">
      {AnalyticsTeamProfile.map((category) => (
        <CategoryTeam key={category.id} ourTeam={category} />
      ))}
    </div>
  );
};

export default AnalyticsTeam;
