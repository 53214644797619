import Frame155 from "../assets/Frame155.png";
import Frame156 from "../assets/Frame156.png";
import Frame157 from "../assets/Frame157.png";
import Frame158 from "../assets/Frame158.png";
import Frame159 from "../assets/Frame159.png";
import Frame160 from "../assets/Frame160.png";

const OurValuesJson = [
  {
    imageUrl: Frame155,
    title: "Creativity",
    content:
      "We believe in the power of creative thinking and its ability to spark innovative solutions. Our commitment to creativity drives us to constantly push boundaries and explore new possibilities.",
  },
  {
    imageUrl: Frame156,
    title: "Collaboration",
    content:
      "Collaboration is at the heart of everything we do. We collaborate with our clients, partners, and each other to achieve collective success. Together, we achieve more.",
  },
  {
    imageUrl: Frame157,
    title: "Integrity",
    content:
      "We uphold the highest ethical standards in all our interactions. Trust and integrity are the cornerstones of our relationships with clients, employees, and partners.",
  },

  {
    imageUrl: Frame158,
    title: "Innovation",
    content:
      "Innovation is the lifeblood of our organization. We are dedicated to staying at the forefront of technological advancements and industry trends to deliver solutions that drive progress.",
  },
  {
    imageUrl: Frame159,
    title: "Client-Centric",
    content:
      "Our clients are our top priority. We are committed to understanding their unique needs and providing tailored solutions that exceed their expectations.",
  },
  {
    imageUrl: Frame160,
    title: "Continuous Learning",
    content:
      "In a rapidly changing world, we believe in continuous learning and development. We encourage our team members to grow both personally and professionally to better serve our clients.",
  },
];
export default OurValuesJson;
