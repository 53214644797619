import React from "react";
import ProjectImageCard from "../../components/ProjectImageCard";
import { star } from "../../assets/index";
import details from "../../Data/OurProjectDetails";
import ProjectCard from "../../components/ProjectCard";
import ComingSoon from "../../components/UI/ComingSoon";

const Project: React.FC = () => {
  return (
    <div
      className="break-normal"
      style={{ backgroundColor: "#222222", color: "white" }}
    >
      <div className="max-w-screen-xl mx-auto overflow-hidden py-10">
        <h2 className="text-xl ml-10 flex flex-col gap-2 font-semibold">
          Reset your expectations for{" "}
          <span>
            <h2 className="animate-text bg-gradient-to-l from-sky-800 via-indigo-900 to-pink-800  bg-clip-text text-transparent text-2xl font-black">
              Consulting Management Tools
            </h2>
          </span>
        </h2>
        {details.map((item, index) => (
          <div
            key={index}
            className={`flex flex-col sm:flex-row ${
              (index + 1) % 2 === 0 ? "sm:flex-row-reverse" : ""
            }  justify-around gap-12 pt-10 `}
          >
            <div>
              <ProjectImageCard
                imgPath={item.imgpath}
                conStyle="w-[70%]"
                showBackground={true}
                imagePositionLeft={(index + 1) % 2 === 0 ? true : false}
              />
            </div>
            <div className="flex flex-col gap-8 sm:block p-5">
              <div>
                <ComingSoon text="Coming Soon" location={star} />
              </div>
              <div>
                <ProjectCard
                  conStyle={item.conStyle}
                  title={item.title}
                  titleStyle="font-bold text-xl py-2"
                  description={item.description}
                  descriptionStyle="text-gray-300 min-w-300 text-justify"
                  showButton={false}
                  btnName="View"
                  navigate={true}
                  to={`/ourProducts?item=${item.title.toLowerCase()}`}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Project;
