import React, { InputHTMLAttributes } from "react";
import { BsEyeSlash } from "react-icons/bs";
import { BsEye } from "react-icons/bs";

interface IFormInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelstyle?: any;
  inputstyle?: any;
}

const FormInput = React.forwardRef<HTMLInputElement, IFormInputProps>(
  ({ label, id, ...props }, ref) => {
    const [showPassword, setShowPassword] = React.useState(false);

    const handleTogglePassword = () => {
      setShowPassword(!showPassword);
    };

    return (
      <div className="flex flex-col gap-2 justify-center relative">
        {label && (
          <label className={`${props.labelstyle} `} htmlFor={id}>
            {label}
          </label>
        )}
        <div></div>
        <input
          id={id}
          ref={ref}
          {...props}
          className={`${props.inputstyle} bg-[#e6e6e7] p-2.5 rounded-md`}
          type={showPassword ? "text" : props?.type}
        />
        <div
          onClick={handleTogglePassword}
          className="absolute right-4 bottom-3"
        >
          {props.type === "password" && (
            <div>
              {showPassword ? (
                <BsEyeSlash className="text-black" />
              ) : (
                <BsEye className="text-black" />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default FormInput;
