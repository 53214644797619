import React from "react";
import twitter from "../../../assets/twitterlogo.svg";
import facebook from "../../../assets/fblogo.svg";
import linkedin from "../../../assets/linklogo.svg";
import instagram from "../../../assets/inslogo.svg";

const Social = () => {
  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-center text-white">Follow us on</div>
      <div className="flex flex-row justify-center gap-4 pb-3">
        <a
          href="https://www.facebook.com/people/XBI4/100086430014347/"
          target="_blank"
        >
          <img src={facebook} className="w-5" alt="Facebook" />
        </a>
        <a href="https://www.instagram.com/xbi4_inc/" target="_blank">
          <img src={instagram} className="w-5" alt="Instagram" />
        </a>
        <a
          href="https://www.linkedin.com/company/xbi4/mycompany/"
          target="_blank"
        >
          <img src={linkedin} className="w-5" alt="LinkedIn" />
        </a>
        <a href="https://twitter.com/Xbi4I" target="_blank">
          <img src={twitter} className="w-5" alt="Twitter" />
        </a>
      </div>
    </div>
  );
};

export default Social;
